$(document).ready(function (e) {

    function stopAllYouTubeVideosOnPage() {
        // stop/pause all videos on the page from playing, if any...
        $(".videoIframe").each(function () {
            var vsrc = $(this).attr("src");
            vsrc = vsrc.replace("&autoplay=1", "");
            vsrc = vsrc.replace("&autoplay=0", "");
            vsrc = vsrc.replace("?autoplay=1", "");
            vsrc = vsrc.replace("?autoplay=0", "");
            $(this).attr("src", vsrc);
        });
    }

    $(".video-play,.videoIframe").on("mouseleave focusout", function () {
        // Only resume sliders if ALL videos in frames have stopped
        var allVideosStopped = true;
        $('.videoIframe').each(function () {
            var vsrc = $(this).attr("src");
            // the presence of autoplay tag in any form would indicate a video is still playing or paused...
            // in other words the user may have eyes on the video...in this case we don't want to auto stop...
            if (vsrc.indexOf("?autoplay=") > 0 || vsrc.indexOf("&autoplay=") > 0) {
                allVideosStopped = false;
            }
        });

        if (allVideosStopped === true) {

            // stop any existing youtube videos from playing
            stopAllYouTubeVideosOnPage();
        }
    });

    $(".video-play").on("click touch", function () {
        stopAllYouTubeVideosOnPage(); // stop any existing videos from playing, if any

        // ensure overlay is restored...
        $(".video").each(function () {
            $(this).css("z-index", "-1");
        });

        var vidCoverDiv = $(this).parent();
        var vidContainer = vidCoverDiv.next(".video");
        if (vidContainer !== null && vidContainer !== undefined) {
            vidContainer.css("z-index", "99"); // bring frame container to front
            var vidFrame = vidContainer.children()[0];
            if (vidFrame !== null && vidFrame !== undefined) {
                var vsrc = $(vidFrame).attr('src');
                if (vsrc !== null && vsrc !== undefined) {
                    if (vsrc.includes("?")) {
                        vsrc = vsrc.replace("&autoplay=1", "");
                        vsrc = vsrc.replace("&autoplay=0", "");
                        vsrc += "&autoplay=1";
                    }
                    else {
                        vsrc = vsrc.replace("?autoplay=1", "");
                        vsrc = vsrc.replace("?autoplay=0", "");
                        vsrc += "?autoplay=1";
                    }
                    console.log(vsrc);
                    $(vidFrame).attr("src", vsrc); // this will start playing the video!
                }
            }
        }
    });

});
