(function (rivermarkcu, $) {

    $(document).ready(function () {

        // Set focus on dialog when Login Dialog opens
        $("#loginDialog").fancybox({
            afterShow: function () {
                var $modalBannerImage = $('.login-form-modal-wrapper .login-banner-link');
                if ($modalBannerImage.length > 0) {
                    $modalBannerImage.focus();
                } else {
                    $(".usernameInput").focus();
                }

                $(".fancybox-close-small").attr("aria-label", "close the dialog");
            },
            keys: {
                close: [27]
            }
        });

        // accessibility fix for mobile live person chat nav item
        $('#lpChatLinkWebsiteMobile, #lpChatLinkWebsite').on('click keydown', function (e) {
            e.stopPropagation();
            if ((e.target.id === 'lpChatLinkWebsiteMobile' || e.target.id === 'lpChatLinkWebsite') && e.type === 'click' || e.keyCode === 13) {
                $(this).find('.LPMcontainer div').click();
            }
        });

        // Activity blocker
        function showActivityBlocker() {
            $(".activity_blocker").css("height", $("body").css("height")).show();
        }
        function hideActivityBlocker() {
            $(".activity_blocker").css("height", 0).hide();
        }

        var support_active = false;

        function showSupportPopover() {
            support_active = true;

            $(".utilities_support").show();
            showActivityBlocker();
            $(".authentication_backdrop").show();
            $(".authentication_backdrop").css("height", "10em");

            $(".nav_container").hide();

            $(".mob_support_popover_toggle").closest("li").addClass("selected");
            $(".mob_support_popover_toggle").find(".normal").hide();
            $(".mob_support_popover_toggle").find(".highlight").show();
            //showActivityBlocker();

        }
        function hideSupportPopover() {
            support_active = false;

            $(".utilities_support").hide();
            hideActivityBlocker();
            $(".authentication_backdrop").hide();
            $(".authentication_backdrop").css("height", "15em");

            $(".nav_container").show();



            $(".mob_support_popover_toggle").closest("li").removeClass("selected");
            $(".mob_support_popover_toggle").find(".normal").show();
            $(".mob_support_popover_toggle").find(".highlight").hide();
            //hideActivityBlocker();;
        }

        function toggleSupportPopover() {
            if (!support_active) {
                showSupportPopover();
            } else {
                hideSupportPopover();
            }
        }

        // Mobile authentication drop down

        var auth_active = false;

        var AUTH_POPOVER_TYPE = {
            NONE: 0,
            PHONE: 1,
            TAB: 2
        };

        var auth_popover_type = AUTH_POPOVER_TYPE.TAB;



        function showAuthPopover() {
            auth_active = true;
            /*Time Trade*/
            $(".tab_auth_popover_toggle").css("background", "#444f62");

            $(".authentication_container").show();
            showActivityBlocker();
            $(".authentication_backdrop").show();
            $(".mega_menu_container").hide();

            if (auth_popover_type === AUTH_POPOVER_TYPE.PHONE) {
                $(".utilities_authentication").show();
                $(".mob_auth_popover_toggle").css("background", "#fff");
                $(".mob_auth_popover_toggle .sign_in").css("background-position", "24px -29px");
                $(".mob_auth_popover_toggle span").css("color", "#07152e");
                $(".mob_auth_popover_toggle span").css("font-weight", "bold");
            }

        }
        function hideAuthPopover() {
            auth_active = false;

            $(".tab_auth_popover_toggle").css("background", "none");
            $(".authentication_container").hide();
            $(".authentication_backdrop").hide();

            hideActivityBlocker();
            $(".mega_menu_container").show();

            $(".mob_auth_popover_toggle").css("background", "none");
            $(".mob_auth_popover_toggle .sign_in").css("background-position", "24px 0px");
            $(".mob_auth_popover_toggle span").css("color", "#fff");
            $(".mob_auth_popover_toggle span").css("font-weight", "normal");

            if (auth_popover_type === AUTH_POPOVER_TYPE.PHONE) {
                $(".utilities_authentication").hide();
                $(".mob_auth_popover_toggle").css("background", "none");
                $(".mob_auth_popover_toggle .sign_in").css("background-position", "24px 0px");
                $(".mob_auth_popover_toggle span").css("color", "#fff");
                $(".mob_auth_popover_toggle span").css("font-weight", "normal");
            }
        }

        function toggleAuthPopover() {
            if (!auth_active) {
                showAuthPopover();
            } else {
                hideAuthPopover();
            }
        }

        $(".tab_auth_popover_toggle").on("click", function (event) {
            event.preventDefault();
            auth_popover_type = AUTH_POPOVER_TYPE.TAB;
            toggleAuthPopover();
        });

        /***** TimeTrade *****/
        $(".activity_blocker").on("click", function (event) {
            event.preventDefault();
            auth_popover_type = AUTH_POPOVER_TYPE.TAB;
            toggleAuthPopover();
        });

        $(".mob_auth_popover_toggle").on("click", function (event) {
            event.preventDefault();

            auth_popover_type = AUTH_POPOVER_TYPE.PHONE;
            if (support_active) {
                hideSupportPopover();
            }
            toggleAuthPopover();

        });

        $(".mob_support_popover_toggle").on("click", function (event) {
            event.preventDefault();
            auth_popover_type = AUTH_POPOVER_TYPE.PHONE;
            if (auth_active) {
                hideAuthPopover();
            }
            toggleSupportPopover();
        });

        setupPlaceholders();

        // =================================================
        $('.styled_select select').customSelect();

        /// Video Lightbox Popup using http://dimsemenov.com/plugins/magnific-popup/

        $('.youtube-sidebar-block-lightbox').magnificPopup({
            type: 'iframe'
        });

        setupMenu();

        /*****Need A Loan Menu*****/
        if (navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i)) {

            $(".apply_for_loan").bind("click", show);

        } else {
            $(".apply_for_loan").bind("mouseover", mouseOn);
            $(".apply_for_loan").bind("mouseout", mouseOut);
            $(".loans_menu li").on("mouseover", loanActive);
            $(".loans_menu li").on("mouseout", loanNonactive);
        }

        var $mobileMenuPanelTitles = $('.mm-navbar__title');
        $mobileMenuPanelTitles.each(function () {
            var $panelTitle = $(this);
            $panelTitle.attr("aria-label", "Close submenu");
        });

        setupSiteSearch();

        // Setup placeholder text on sign in form for browser's without support
        function setupPlaceholders() {
            var $authUserName = $('input[name=userid]');
            var authUserNamePlaceholderText = $authUserName.attr('placeholder');
            $authUserName.on("focus", function () {
                var $this = $(this);
                if ($this.val() === authUserNamePlaceholderText) {
                    $this.val('');
                }
            }).on("blur", function () {
                var $this = $(this);
                if ($this.val() === '') {
                    $this.val(authUserNamePlaceholderText);
                }
            });

            var $askText = $('#ask_text');
            var askPlaceholderText = $askText.attr('placeholder');
            $askText.on("focus", function () {
                var $this = $(this);
                if ($this.val() === askPlaceholderText) {
                    $this.val('');
                }
            }).on("blur", function () {
                var $this = $(this);
                if ($this.val() === "") {
                    $this.val(askPlaceholderText);
                }
            });

            $('#contact_message_field').on("focus", function () {
                var $this = $(this);
                if ($this.val() === "Example input text...") {
                    $this.val('');
                }
            }).on("blur", function () {
                var $this = $(this);
                if ($this.val() === "") {
                    $this.val('Example input text...');
                }
            });

            var $loginForms = $('.login_form');
            $loginForms.each(function () {
                var $this = $(this);
                var $passwordLabel = $this.find('.password_container label');
                var $loginPassword = $this.find('input[name=password]');
                var $loginPasswordClear = $this.find('input[name=password_clear]');
                $loginPasswordClear.show();
                $loginPassword.hide();

                // label "for" logic handled here because of password_clear placeholder text
                $passwordLabel.on('click', function () {
                    if ($loginPassword.is(':visible')) {
                        $loginPassword.focus();
                    } else {
                        $loginPasswordClear.focus();
                    }
                });

                $loginPasswordClear.on('focus', function () {
                    $loginPasswordClear.hide();
                    $loginPassword.show();
                    $loginPassword.focus();
                });

                $loginPassword.on('blur', function () {
                    if ($loginPassword.val() === '') {
                        $loginPasswordClear.show();
                        $loginPassword.hide();
                    }
                });
            });
        }

        function mouseOn(e) {
            $(".loans_menu").css({ "display": "block" });
        }

        function loanActive(e) {
            $(e.currentTarget).css({ "background-color": "#e7e8e8" });
        }

        function loanNonactive(e) {
            $(e.currentTarget).css({ "background-color": "rgba(255, 255, 255, 0)" });
        }

        function mouseOut(e) {
            $(".loans_menu").css({ "display": "none" });
        }

        function show(e) {
            $(".apply_for_loan").unbind("click", show);

            $(".loans_menu").css({ "display": "block" });
            $(".apply_for_loan").bind("click", hide);
        }

        function hide(e) {
            $(".apply_for_loan").unbind("click", hide);

            $(".loans_menu").css({ "display": "none" });
            $(".apply_for_loan").bind("click", show);
        }
        /**************************/

        //Password Manager
        /*var postCredentials = function (memberId, password) {
        
            console.log("Testing Post Credentials!");
        
            var apiRoot = "http://srvapprdev01/PassMan/";
        
            $.ajax(apiRoot + "member/" + memberId + "/password/" + password + "",
                function () { })
                .done(function () { })
                .error(function (jqXhr, status, err) { });
        }*/

        /// Sliding in nav (MMenu)
        function setupMenu() {
            var $slideMenu = $('#mmenu');
            $slideMenu.mmenu({
                "offCanvas": {
                    "position": "left"
                },
                navbar: {
                    title: ""
                },
                keyboardNavigation: {
                    enable: true,
                    enhance: true
                },
                screenReader: {
                    aria: true,
                    text: {
                        closeMenu: "Close menu",
                        closeSubmenu: "Close submenu",
                        openSubmenu: "Open submenu",
                        toggleSubmenu: "Toggle submenu"
                    }
                },
                "extensions": [
                    "border-full",
                    "theme-light"
                ]
            },
                {
                    panelNodetype: "div",
                    panelClass: "rmPanel"
                });
        }

        function setupSiteSearch() {
            var $desktopSearch = $('.navCtaContainer .site-search');
            var $mobileSearch = $('.mmenu .site-search');
            if ($desktopSearch && $desktopSearch.length > 0) {
                setupSiteSearchTrigger($desktopSearch);
            }

            if ($mobileSearch && $mobileSearch.length > 0) {
                setupSiteSearchTrigger($mobileSearch);
            }
        }

        function setupSiteSearchTrigger($searchWrapperEl) {
            var searchResultsPage = $searchWrapperEl.attr('data-results-page');
            var $searchLink = $searchWrapperEl.find(".search-link");
            if ($searchLink.length > 0) {

                var executeSearch = function () {
                    var $searchTermsInput = $searchWrapperEl.find('input[name="q"]');
                    if (window.location.pathname === searchResultsPage) {
                        // already on the search page. Just update hash and reload.
                        window.location.hash = '#!q=' + $searchTermsInput.val();
                        window.location.reload();
                    } else {
                        window.location.href = searchResultsPage + '#!q=' + $searchTermsInput.val();
                    }

                }

                $searchLink.on('click', executeSearch);
                $searchWrapperEl.on('keydown', function (e) {
                    if (e.which === 13) {
                        executeSearch();
                    }
                });
            }
        }

        window.onscroll = function () { scrollFunction() };

        function scrollFunction() {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                document.getElementById("rm-header").classList.add("scrolling");
            } else {
                document.getElementById("rm-header").classList.remove("scrolling");
            }
        }

    });



}(window.rivermarkcu = window.rivermarkcu || {}, jQuery));

