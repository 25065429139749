(function (rivermarkcu, $) {

    rivermarkcu.GlobalScripts = function () {

        // TODO: Add description
        function renderSmartAppBanner() {
            var banner = new SmartBanner({
                daysHidden: 2, // days to hide banner after close button is clicked (defaults to 15)
                daysReminder: 7, // days to hide banner after "VIEW" button is clicked (defaults to 90)
                appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
                title: 'Mobile Banking',
                author: 'Rivermark Community Credit Union',
                button: 'VIEW',
                store: {
                    ios: 'On the App Store',
                    android: 'In Google Play',
                    windows: 'In Windows store'
                },
                price: {
                    ios: 'FREE',
                    android: 'FREE',
                    windows: 'FREE'
                }
                // , force: 'ios' // Uncomment for platform emulation
            });
        }

        // Sets up the speedbump on the rivermarkcu site. The speedbump is meant
        // to prevent users from accidentally leaving the rivermarkcu site without
        // being aware.
        function initializeSpeedbump() {
            var whitelist = $("body").attr("data-whitelist");
            var proceed = false;

            if (whitelist) {
                whitelist = whitelist.replace(/\s/g, "");
                whitelist = whitelist.replace(/,/g, "|");
                whitelist = whitelist.replace(/[\||\s]*$/, "");
                $('a:not([href=""])').each(function() {
                    var $this = $(this);
                    var url = $this.attr("href");
                    var self;

                    if (/^(f|ht)tps?:\/\//i.test(url)) {
                        var iswhitelisted = false;
                        if (whitelist.length > 0) {
                            var pattern = new RegExp("(" + whitelist + ")", "i");
                            iswhitelisted = pattern.test(url);
                        }

                        //If using a youtube lightbox, we don't want to show the speedbump as the user is staying on the site.
                        var isLightbox = false;
                        if ($this.hasClass("youtube-sidebar-block-lightbox")) {
                            isLightbox = true;
                        }

                        if (this.hostname !== location.hostname &&
                            !iswhitelisted &&
                            !isLightbox &&
                            $this.hasClass("externalRedirectOK") == false) {
                            $(this).bind("click", showSpeedbumpModal);
                        }
                    }
                });
            }
        }

        function setupAccordionAccessibility() {
            var $tabControls = $('.tab button.tab-control');
            $tabControls.on('click', function () {
                var $tabControl = $(this);
                var $targetTabContentId = $tabControl.attr('aria-controls');
                if ($targetTabContentId) {
                    var $tabContent = $tabControl.next('#' + $targetTabContentId);
                    $tabControl.attr('aria-expanded', $tabControl.attr('aria-expanded') === 'true' ? 'false' : 'true');
                    $tabContent.attr('aria-hidden', $tabContent.attr('aria-hidden') === 'true' ? 'false' : 'true');
                }
            });
        }

        // This is required in addition to the fancybox close config because forms break fancybox's close key
        function setupFancyboxCloseOnEsc() {
            $(document).on('keydown', function (e) {
                if (e.which === 27) {
                    $.fancybox.close();
                }
            });
        }

        // Replace minimal accessibility outline with full outline
        // when keyboard navigation is used
        function applyAccessibilityOutline() {
            $(document).on('keydown', function (e) {
                if (e.which === 9) {
                    document.documentElement.removeAttribute('minimal-focus-outline');
                }
            });
        }

        // FAQ Filter 
        $(".results_toolbar .filter_icon").click(function () {
            if ($(".results_toolbar + .faq_filters").hasClass("hide"))
                $(".results_toolbar + .faq_filters").removeClass("hide").addClass("show");
            else
                $(".results_toolbar + .faq_filters").removeClass("show").addClass("hide");
        });

        // Presents users with a modal window that will require their consent before leaving
        // the rivermarkcu site.
        function showSpeedbumpModal(e) {
            e.preventDefault();

            //console.log("Target: " + $(e.currentTarget).attr("href"));

            if ($(e.currentTarget).attr("href")) {
                var url = $(e.currentTarget).attr("href");
                $("#popup .clickable").attr("href", url);
                $("#popup .clickable").attr("target", "_blank");

            }

            //$("a.rmBtn").unbind("click", showSpeedbumpModal); //These two lines seem to be some testing code... should be removed.
            //$("#popup").unbind("click", showModal);

            // var content = $(e.currentTarget).closest('.legal-port').find('.legal-content');
            if (!popup) {
                var popup = new Popup($('#popup'));
                popup.addEvents();
                $("#popup .clickable").bind("click", $.proxy(popup.hide, popup));
                //$(popup).on('changeState', modalClosed);
                popup.populate('.popup-container');
                $(document).on('keyup.escapePopup', function (key) {
                    if (key.keyCode == 27) {
                        popup.hide(e);
                    }
                });
            }
            $("#popup-close-btn").focus();
            $("#popup-close-btn").on('keydown', function (key) {
                if (key.keyCode == 9) {
                    key.preventDefault();
                    //send focus to submit button
                    $("#popup .clickable").focus();
                    console.log("focused submit");
                }
            });
            $("#popup .clickable").on('keydown', function (key) {
                if (key.keyCode == 9) {
                    key.preventDefault();
                    //send focus to cancel button
                    $("#popup-close-btn").focus();
                    console.log("focused close");
                }
            });
        }

        function calculateInsuritasUrl(url) {
            var place = "";
            tab = $("#insuritas_zip").val();
            if (tab == '') {
                resetInsuritas("Enter Zipcode");
                return place;
            } else if (isNaN(tab)) {
                resetInsuritas("Enter Zipcode");
                return place;
            } else if (tab.length < 5) {
                resetInsuritas("Enter Zipcode");
                return place;
            } else {
                addon = $("#insuritas_zip").val();
                $("#insuritas_zip").css("border", "1px solid #848686");

                if (url.indexOf('?') != -1) {
                    place = url + "=" + tab;
                } else {
                    place = url;
                }
                return place;
            }
        }

        function resetInsuritas(message) {
            $("#insuritas_zip").css('border', '1px solid red');
            $("#insuritas_zip").val(message);
            $("#insuritas_sel :nth-child(1)").prop('selected', true);
        }

        function cleanInsuritas(e) {
            $("#insuritas_zip").val("");
        }

        function updateInsuritas(e) {
            if ($("#insuritas_zip").val() == "") {
                $("#insuritas_zip").val("ZIP CODE");
            }
        }

        function formatMarketoForms() {
            if (typeof MktoForms2 !== 'undefined') {
                MktoForms2.whenRendered(function (form) {
                    var mFormEl = form.getFormElem()[0];
                    jQuery(mFormEl).find('*').andSelf().removeAttr('style');
                    jQuery(window).resize(function () {
                        // hack to repeatedly remove inline styles added by marketo on resize
                        jQuery(mFormEl).find('*').andSelf().removeAttr('style');
                    });
                });
            }
        }

        function setupVideoChat() {
            var $videoChatBtn = $('.video-chat-button');
            $videoChatBtn.on('click', function () {
                var $this = $(this);
                var chatSrc = $this.attr('data-src');
                if (chatSrc && chatSrc.startsWith('http')) {
                    window.open(chatSrc);
                }
            });
        }

        var globalScripts = {
            renderSmartAppBanner: renderSmartAppBanner,
            setupSpeedbump: initializeSpeedbump,
            calculateInsuritasUrl: calculateInsuritasUrl,
            cleanInsuritasWidget: cleanInsuritas,
            updateInsuritasWidget: updateInsuritas,
            applyAccessibilityOutline: applyAccessibilityOutline,
            setupAccordionAccessibility: setupAccordionAccessibility,
            setupFancyboxCloseOnEsc: setupFancyboxCloseOnEsc,
            formatMarketoForms: formatMarketoForms,
            setupVideoChat: setupVideoChat
        };

        return globalScripts;
    }();

    // Execute Scripts
    $(document).ready(function () {
        jQuery.curCSS = jQuery.css;

        window.rivermarkcu.GlobalScripts.renderSmartAppBanner();

        window.rivermarkcu.GlobalScripts.setupSpeedbump();

        window.rivermarkcu.GlobalScripts.applyAccessibilityOutline();

        window.rivermarkcu.GlobalScripts.setupAccordionAccessibility();

        window.rivermarkcu.GlobalScripts.setupFancyboxCloseOnEsc();

        window.rivermarkcu.GlobalScripts.formatMarketoForms();

        window.rivermarkcu.GlobalScripts.setupVideoChat();

        /////////////////////////////////////////////////////////////////////////////////
        // Row / Div Click
        $('.clickable-arrow').click(function () {
            var locationUrl = $(this).attr("data-click-location");
            if (locationUrl.length > 0) {
                document.location = locationUrl;
            }
        });

        $('.clickable-arrow-external-redirect').click(function () {
            var locationUrl = $(this).attr("data-click-location");
            if (locationUrl.length > 0) {
                //document.location = "/external-redirect.aspx?x=" + locationUrl;
                document.location = locationUrl;
            }
        });
        /////////////////////////////////////////////////////////////////////////////////

        // Alert
        var $alert_container = $(".alert_container");
        $(".alert_close").click(function (event) {
            event.preventDefault();
            $alert_container.animate({
                height: "0"
            }, 200, function () {
                $alert_container.remove();
            });
            // TODO - FIX THIS MADNESS
            /*$(".nav_container").animate({
                top: "12em"
            }, 200);*/
            $(".alert").fadeOut(100);
        });

        //// Insuritas Get Quote
        if ($(".insuritasgetquote").length > 0) {
            $(".insuritasgetquote #insuritaszip").focus(function () {
                $(this).val("");
                $(".insuritasgetquote .alert").hide();
            });

            $("#contour .getQuote").click(function () {
                var $zip = $(".insuritasgetquote #insuritaszip");
                zipcode = $zip.val();
                var $alert = $(".insuritasgetquote .alert");
                if (zipcode.length < 5) {
                    $alert.show();
                } else {
                    window.location.href = $(".insuritasgetquote #quotetype").val() + "=" + zipcode;
                }
            });
        }



        ////****Insuritas Widget******//

        $("#insuritas_button").click(function () {
            var url = window.rivermarkcu.GlobalScripts.calculateInsuritasUrl($('#insuritas_sel').val());

            if (url != "") {
                window.open(url, '_blank');
            }
        });

        $("#insuritas_zip").on("focus", window.rivermarkcu.GlobalScripts.cleanInsuritasWidget);
        $("#insuritas_zip").on("blur", window.rivermarkcu.GlobalScripts.updateInsuritasWidget);

        // Insuritas Image Rotator
        var toutController;
        var toutCount = 0;
        var callbackCount = 0;
        var toutURL = 'http://rivermark.insuranceaisle.com/vehicle-insurance';
        var toutContent = [
            { url: 'http://rivermark.insuranceaisle.com/vehicle-insurance', type: 'auto' },
            { url: 'http://rivermark.insuranceaisle.com/property-insurance', type: 'home' },
            { url: 'http://rivermark.insuranceaisle.com/quicklifeyourinfo', type: 'life' },
            { url: 'http://rivermark.insuranceaisle.com/business-insurance', type: 'business' }
        ];
        if ($('.insuritas_widget').html()) {
            startToutRotator();
            addToutEvents();
        }

        function startToutRotator() {
            toutController = setInterval(toutRotatorOut, 4000);
        }

        function routeMember(e) {

            console.log("Route Member: " + toutURL);

            window.open(toutURL, '_blank');
        }

        function toutRotatorOut() {
            clearInterval(toutController);
            $('.insuritas_widget .tout_window').unbind('click', routeMember);
            $('.insuritas_widget .tout_rotator').unbind('click', routeMember);
            TweenLite.to($('.insuritas_widget .tout_container'), 0, { alpha: 0, onComplete: toutInvisible });
            toutCount++;

            if (toutCount > 3) {
                toutCount = 0;
            }
        }

        function toutRotatorIn() {
            $('.insuritas_widget .tout_window').bind('click', routeMember);
            $('.insuritas_widget .tout_rotator').bind('click', routeMember);
            toutURL = toutContent[toutCount].url;
            TweenLite.to($('.insuritas_widget .tout_container'), 0, { alpha: 1, onComplete: startToutRotator });
        }

        function toutInvisible() {
            callbackCount = 0;
            updateToutContent();
            updateToutRotatorContent();
            toutRotatorIn();
        }

        function updateToutContent() {
            var position = '0px ' + (toutCount * -173) + 'px';
            $('.tout_container .tout_window').css({ 'background-position': position });
        }
        function updateToutRotatorContent() {
            var position = '0px ' + (toutCount * -141) + 'px';
            $('.tout_container .tout_rotator').css({ 'background-position': position });
        }

        function addToutEvents() {
            $('.insuritas_widget .tout_window').bind('click', routeMember);
            $('.insuritas_widget .tout_rotator').bind('click', routeMember);
        }
        //////////////////////////////

        //*****Insuritas Widget End********//

        /* Digital Insight Account Access scripts */
        function enableMe() {
            $("input[name=dscheck]").val('0');
        }
        function isCookieEnabled() {
            var exp = new Date();
            exp.setTime(exp.getTime() + 1800000);
            setCookie("testCookie", "cookie", exp, false, false, false);
            if (document.cookie.indexOf('testCookie') == -1) {
                return false;
            }
            exp = new Date();
            exp.setTime(exp.getTime() - 1800000);
            setCookie("testCookie", "cookie", exp, false, false, false);
            return true;
        }
        function setCookie(name, value, expires, path, domain, secure) {
            var curCookie = name + "=" + value +
                ((expires) ? "; expires=" + expires.toGMTString() : "") + ((path) ? "; path=" + path : "") +
                ((domain) ? "; domain=" + domain : "") + ((secure) ? "; secure" : "");
            document.cookie = curCookie;
        }
        function isDupSubmit() {
            var dupSbmt = true;
            var $e = $("input[name=dscheck]");
            if ($e != null && $e.val() === '0') {
                dupSbmt = false; $e.val('1'); setTimeout(enableMe, 5000);
            }
            return dupSbmt;
        }
        function setParamStatus() {
            if (!isDupSubmit()) {
                if (isCookieEnabled()) {
                    $('input[name=testcookie]').val('true');
                }
                $('input[name=testjs]').val('true');
                return true;
            }
            return false;
        }

        /* END of Digital Insight Account Access scripts */

        /* LoanApplication Save & Continue */

        //Finds the Token URL
        var search = window.location.search;

        //Appends the Token to the URL
        if (search.toLowerCase().indexOf("internetappid") > -1) {
            $("#Loan360").attr("src", $("#Loan360").attr("src") + search);
        }

        /* END Loan Application Save & Continue */
    });

}(window.rivermarkcu = window.rivermarkcu || {}, jQuery));

