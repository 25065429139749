(function (rivermarkcu, $) {

    rivermarkcu.AppointmentScripts = function () {

        function setupAppointments() {

            $('.appt_trigger').fancybox({
                iframe: {
                    preload: false
                },
                keys: {
                    close: [27]
                },
                toolbar: false
            });

            // Create IE + others compatible event handler
            var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            var eventer = window[eventMethod];
            var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
            // Listen to message from child window
            eventer(messageEvent, function (e) {
                if (e.data == 'CloseWidget') {
                    var fbInstance = $.fancybox.getInstance();
                    if (fbInstance) {
                        fbInstance.close();
                    }
                }
            }, false);
        }

        var appointmentScripts = {
            setupAppointments: setupAppointments
        };

        return appointmentScripts;
    }();

    // Execute Scripts
    $(document).ready(function ($) {
        window.rivermarkcu.AppointmentScripts.setupAppointments();
    });

}(window.rivermarkcu = window.rivermarkcu || {}, jQuery));



