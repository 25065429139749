"use strict";

//Constructor
function Animator() {

}

//Reset to alpha none
Animator.prototype.reset = function (el) {
    this.el = el;
    TweenLite.to(this.el, 0, { alpha: 0 });
}

Animator.prototype.activate = function (el) {
    this.el = el;
    this.el.css({ "display": "block" });
}

//Show Fade In Animation 
Animator.prototype.show = function (el, duration, alpha, delay) {
    this.el = el;
    duration || duration == 0 ? duration : duration = 1;
    alpha ? alpha : alpha = 0.0;
    delay ? delay : delay = 0;

    this.reset(this.el);
    this.activate(this.el);
    TweenLite.to(this.el, duration, { alpha: 1, ease: Power2.easeOut });
}

//Hode Fade out Animation
Animator.prototype.hide = function (el, duration, custom) {
    this.el = el;
    custom ? custom : custom == undefined;
    duration || duration == 0 ? duration : duration = 1;
    TweenLite.to(this.el, duration, { alpha: 0, ease: Power2.easeOut, onComplete: this.deactivate, onCompleteParams: [this.el, custom] });
}

Animator.prototype.deactivate = function (el, custom) {
    this.el = el;
    this.el.css({ "display": "none" });
    if (custom !== undefined) {
        this.el.find(custom).css({ "display": "none" });
    }
}


Animator.prototype.scrollPage = function (loc, delay) {
    delay ? delay : delay = 0;

    console.log("Page - loc: " + loc);
    console.log("Page - delay: " + delay);
    console.log("Page - window: " + window);
    console.log("Page - EaseOut: " + Power2.easeOut);

    TweenLite.to(window, .5, { delay: delay, scrollTo: {y: loc }, ease: Power2.easeOut });
}


Animator.prototype.scrollPageUp = function (loc, delay) {
    delay ? delay : delay = 0;
    TweenLite.to(window, .5, {delay: delay, scrollTo: {y: loc }, ease: Power2.easeOut });
}

Animator.prototype.scrollPageDown = function (loc, delay) {
    delay ? delay : delay = 0;
    TweenLite.to(window, .5, { delay: delay, scrollTo: {y: loc }, ease: Power2.easeOut });
}