"use strict";

function Popup(el) {
    this.popup = el;
    this.animator = new Animator();
    this.self = this;
    this.global = {
                    custom: null
                  };
}

//Show Popup
Popup.prototype.show = function (el) {

    if (this.global.custom !== undefined) {
        $(this.popup).find(this.global.custom).css({ 'display': 'block' });
        this.animator.show(this.popup, .35);
    } else {
        $(this.popup).find('.popup-container').css({ 'display': 'block' });
        //Show generic Popup with dynamic content
    }
}

//Hide Popup
Popup.prototype.hide = function(e) {
    e.preventDefault();
    this.animator.hide(this.popup, .25);
    $(this).trigger('changeState');
    document.activeElement.blur();

    this.removeEvents($('.popup-btn-container a, .popup-btn-container button'));
}

Popup.prototype.continue = function (e) {
    if (e) {
        window.open($('#popup-continue-btn').attr('href'));
        this.hide(e);
    }
}

Popup.prototype.populate = function (el, content) {
    el ? el : undefined;
    content ? content : undefined;
    this.global.custom = el;

    if (content !== undefined) {
        $('#popup .popup-header h2').html($(content).find('h3').html());
        $('#popup .popup-content').html($(content).html());
        this.show(this.global.custom);
    } else { //Populate with Dynamic content
       
        this.show();
    }
}

Popup.prototype.addEvents = function () {
    var self = this;
    this.popup.find('#popup-close-btn').bind("click", $.proxy(self.hide, self));
    this.popup.find('.popup-bg').bind("click", $.proxy(self.hide, self));
    this.popup.find('#popup-continue-btn').bind("click", $.proxy(self.continue, self));
}

Popup.prototype.removeEvents = function (el) {
    this.buttons = el;
    var self = this;

    this.buttons.each(function (i) {
        $(this).unbind("click", $.proxy(self.clicked, self));
    });

    this.popup.find('#popup-close-btn').unbind("click", $.proxy(self.hide, self));
    this.popup.find('.popup-bg').unbind("click", $.proxy(self.hide, self));
    this.popup.find('#popup-continue-btn').unbind("click", $.proxy(self.continue, self));
}

//Private functions
Popup.prototype.clicked = function (e) {
    e.preventDefault();

    //Show Elements related to the Popup Clicked
    this.show();
}