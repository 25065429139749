$(document).ready(function () {
    // Unfocus nav when it loses focus
    $(':not(ul.nav *)').on('click', function (e) {
        var $subMenus = $('ul.nav li ul');
        $subMenus.removeClass('js-showElement');
        $subMenus.addClass('js-hideElement');
        $subMenus.attr('aria-hidden', true);
    });
    // When interacting with a li that has a sub menu
    $('ul.nav li:has(ul)').on('mouseover keyup click mouseleave', function (e) {
        var $subMenus = $(this).children('ul');
        // If either -
        // tabbing into the li that has a sub menu
        // hovering over the li that has a sub menu
        if (e.keyCode === 9 | e.type === 'mouseover') {

            // Show sub menu
            $subMenus.removeClass('js-hideElement');
            $subMenus.addClass('js-showElement');
            $subMenus.attr('aria-hidden', false);
        }

        // If mouse leaves li that has sub menu
        if (e.type === 'mouseleave') {

            // hide sub menu
            $subMenus.removeClass('js-showElement');
            $subMenus.addClass('js-hideElement');
            $subMenus.attr('aria-hidden', true);
        }

        // If clicking on li that has a sub menu
        if (e.type === 'click') {

            // If sub menu is already open
            if ($(this).children('a').hasClass('js-openSubMenu')) {

                // remove Open class
                $(this).children('a').removeClass('js-openSubMenu');

                // Hide sub menu
                $subMenus.removeClass('js-showElement');
                $subMenus.addClass('js-hideElement');
                $subMenus.attr('aria-hidden', true);


            // If sub menu is closed
            } else {

                // add Open class
                $(this).children('a').addClass('js-openSubMenu');

                // Show sub menu
                $subMenus.removeClass('js-hideElement');
                $subMenus.addClass('js-showElement');
                $subMenus.attr('aria-hidden', false);

            }

        } // end click event

    });

    // Tabbing through Levels of sub menu
    // ------------------------

    // If TAB is pressed while on the last link in a sub menu
    $('ul.nav li > ul > li:last-child > a:last-child').on('keydown', function(e) {

        // If tabbing out of the last link in a sub menu AND not tabbing into another sub menu
        if (!event.shiftKey && e.keyCode == 9 && $(this).parent('li').children('ul').length == 0) {

            // Close this sub menu
            $(this).parent('li').parent('ul').removeClass('js-showElement');
            $(this).parent('li').parent('ul').addClass('js-hideElement');
            $(this).parent('li').parent('ul').attr('aria-hidden', true);


            // If tabbing out of a third level sub menu and there are no other links in the parent (level 2) sub menu
            if ($(this).parent('li').parent('ul').parent('li').parent('ul').parent('li').children('ul').length > 0 &&
                $(this).parent('li').parent('ul').parent('li').is(':last-child')) {

                // Close the parent sub menu (level 2) as well
                $(this).parent('li').parent('ul').parent('li').parent('ul').removeClass('js-showElement');
                $(this).parent('li').parent('ul').parent('li').parent('ul').addClass('js-hideElement');
                $(this).parent('li').parent('ul').parent('li').parent('ul').attr('aria-hidden', true);
            }
        }
    });

    // If SHIFT+TAB is pressed while on a nav element with a sub nav beneath it
    $('ul.nav li > a, ul.nav li > span').on('keydown', function (e) {

        // If tabbing backwards out of a top level nav item with a sub menu
        if (event.shiftKey && e.keyCode == 9 && $(this).parent('li').children('ul').length > 0) {
            console.log('89');
            // Close this sub menu
            $(this).parent('li').children('ul').removeClass('js-showElement');
            $(this).parent('li').children('ul').addClass('js-hideElement');
            $(this).parent('li').children('ul').attr('aria-hidden', true);
        }
    });
})